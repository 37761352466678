// RoleBasedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../modules/auth'; // Adjust import path as necessary

export const RoleBasedRoute = ({ children, allowedUserTypes, requireApproval }) => {
    const { currentUser } = useAuth();

    // Check if currentUser exists and if admin can bypass the rest of the checks
    const isAdmin = currentUser && currentUser.globalRole === 'admin';

    // Check if the currentUser's userType is included in the allowedUserTypes array
    // and if approvalStatus is true if requireApproval is true
    const hasPermission = currentUser &&
        (allowedUserTypes.includes(currentUser.userType) &&
            (!requireApproval || currentUser.approvalStatus));

    if (!isAdmin && !hasPermission) {
        // Redirect to a different page if the user does not have permission
        return <Navigate to="/error/403" />;
    }

    // Render children if the user has permission or is an admin
    return children;
};

export const AdminOnlyRoute = ({ children }) => {
    const { currentUser } = useAuth();

    // Check if currentUser exists and has a globalRole of 'admin'
    const isAdmin = currentUser && currentUser.globalRole === 'admin';

    if (!isAdmin) {
        // Redirect to a different page if the user is not an admin
        return <Navigate to="/error/403" />;
    }

    // Render children if the user is an admin
    return children;
};