// firestoreService.ts
import {
    collection,
    query,
    serverTimestamp,
    where,
    getDocs,
    addDoc,
    updateDoc,
    arrayRemove,
    doc,
    getDoc,
    deleteDoc,
    limit,
    orderBy,
    startAfter,
    runTransaction,
    setDoc,
    arrayUnion,
    documentId,
    writeBatch,
    QueryDocumentSnapshot,
    DocumentData
} from 'firebase/firestore';
import { db } from './firebaseInit'; // Ensure this path is correct
import { ProjectData, LatestComparisonVersionData, ComparisonResult, SoftwareType, SoftwareItem, SoftwareTypeRelation } from '../app/modules/projects/ProjectsModels';
import { JustificationData } from "../app/modules/justifications/JustificationsModels";
import AuthService from './AuthService'
import { AppUser } from '../app/modules/auth/core/_models';
import { softwareTypesList } from './data/softwareTypes';
import { softwareList } from './data/softwareData';
import { chunk } from 'lodash'; // Assuming you're using lodash for convenience

export const fetchUserProjects = async (userId: string, status?: string): Promise<ProjectData[]> => {

    const userRef = doc(db, `users/${userId}`);
    const userProjectsRef = collection(db, `userProjects`);
    const q = query(userProjectsRef, where('user', '==', userRef));
    const querySnapshot = await getDocs(q);

    const projectPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const projectRef = docSnapshot.data().project;
        const projectSnap = await getDoc(projectRef);

        if (projectSnap.exists()) {
            const projectData = { id: projectSnap.id, ...projectSnap.data() as Omit<ProjectData, 'id'> };
            if (!status || projectData.status === status) {
                return projectData;
            }
        } else {
            console.log(`No project found`);
        }
        return null;
    });

    const projects = (await Promise.all(projectPromises)).filter(project => project !== null) as ProjectData[];
    return projects.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime()); // Sort by created date descending
};

export const fetchUserJustifications = async (userId: string) => {

    const userJustificationsRef = collection(db, `userJustifications`);
    const q = query(userJustificationsRef, where('user', '==', doc(db, `users/${userId}`)));
    const querySnapshot = await getDocs(q);

    const justificationPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const justificationId = docSnapshot.data().justification.id;
        const justificationRef = doc(db, `justifications/${justificationId}`);
        const justificationSnap = await getDoc(justificationRef);

        if (justificationSnap.exists()) {
            return { id: justificationSnap.id, ...justificationSnap.data() as Omit<JustificationData, 'id'> };
        } else {
            console.log(`No justification found for ID: ${justificationId}`);
            return null;
        }
    });

    return (await Promise.all(justificationPromises)).filter(justification => justification !== null) as JustificationData[];
};

export const updateCompanyData = async (companyId, companyData) => {
    const userRef = doc(db, `companies/${companyId}`);
    try {
        await setDoc(userRef, {
            ...companyData
        }, { merge: true }); // This will update the user info fields without overwriting the entire document
        console.log("Company info updated successfully");
    } catch (error) {
        console.error("Error updating company info:", error);
    }
}

export const addUserToCompany = async (userId, userCompanyPayload) => {
    const userRef = doc(db, `users/${userId}`);
    const companyRef = doc(db, `companies/${userCompanyPayload.company}`);
    const userCompanyRef = addDoc(collection(db, 'userCompanies'), {user:userRef, company: companyRef, roleAtCompany: userCompanyPayload.roleAtCompany, active: true});
    return true;
}

export const removeUserFromCompany = async (userId: string, companyId: string) => {
    try {
        // Create a reference to the userCompanies collection
        const userCompaniesRef = collection(db, 'userCompanies');

        // Query to find the specific record where the user and company match
        const q = query(userCompaniesRef, where("user.id", "==", userId), where("company.id", "==", companyId));

        // Execute the query
        const querySnapshot = await getDocs(q);

        // Loop through the results and delete each document found (there should be only one)
        querySnapshot.forEach(async (docSnap) => {
            const userCompanyRef = doc(db, 'userCompanies', docSnap.id);
            await deleteDoc(userCompanyRef);
        });

        console.log(`Successfully removed user ${userId} from company ${companyId}`);
    } catch (error) {
        console.error("Error removing user from company: ", error);
    }
};

// Function to get a user's company information
export const getUserCompany = async (userId: string): Promise<{ companyData: CompanyData; roleAtCompany: string } | null> => {
    try {
        const userRef = doc(db, `users/${userId}`);
        const userCompanyQuery = query(collection(db, 'userCompanies'), where('user', '==', userRef), where('active', '==', true));
        const userCompanySnapshot = await getDocs(userCompanyQuery);

        if (userCompanySnapshot.empty) {
            return null;
        }

        const userCompanyDoc = userCompanySnapshot.docs[0];
        const userCompanyData = userCompanyDoc.data();

        // Get the company details
        const companyDoc = await getDoc(userCompanyData.company);
        const companyData = companyDoc.data() as CompanyData;

        return {
            companyData: {
                id: companyDoc.id,
                ...companyData
            },
            roleAtCompany: userCompanyData.roleAtCompany,
        };
    } catch (error) {
        console.error("Error getting user's company:", error);
        return null;
    }
};

// Check if a user is a member of a specific project
export const isUserAMemberOfProject = async (projectId: string, userId: string) => {
    //todo update
    const userProjectRef = doc(db, `users/${userId}/userProjects/${projectId}`);
    const docSnap = await getDoc(userProjectRef);
    return docSnap.exists();
};

export const isUserQuestionnaireSetup = async (userId: string) => {
    console.log('Fetching questionnaire setup status for user: ' + userId);
    const userDocRef = doc(db, `users/${userId}`);
    const userSnap = await getDoc(userDocRef);

    if (userSnap.exists()) {
        const userData = userSnap.data();
        if (userData.setup) {
            return true;
        }
        return false;

    }
    console.log("No user document found for ID: " + userId);
    return false;
}

export const isCompanyClaimed = async (companyId: string) => {
    console.log('Fetching questionnaire setup status for company: ' + companyId);

    const companyDocRef = doc(db, `company/${companyId}`);
    const companySnap = await getDoc(companyDocRef);

    if (companySnap.exists()) {
        const companyData = companySnap.data();
        if (companyData.claimed) {
            return true;
        }
        return false;

    }
    console.log("No user document found for ID: " + companyId);
    return false;
}

// saveOrUpdateProject function updated to handle user and company associations
export const saveOrUpdateProject = async (projectData: ProjectData, projectId: string | null, userId: string, companyId: string | undefined, userRole: string = 'admin') => {
    let projectRef;

    // Prepare the updated project data
    const updatedProjectData = {
        ...projectData,
        softwareType: projectData.softwareType,
        compareProducts: projectData.compareProducts ? projectData.compareProducts : null, // Assuming it's already an array of {id, name}
        members: { ...projectData.members, [userId]: { role: userRole } },
        modifiedAt: new Date().toISOString()
    };

    console.log("updated project data: ", updatedProjectData);
    if (projectId) {
        // Update existing project
        projectRef = doc(db, `projects/${projectId}`);
        await updateDoc(projectRef, updatedProjectData);
    } else {
        // Create new project
        projectRef = await addDoc(collection(db, 'projects'), updatedProjectData);
        projectId = projectRef.id; // Get the new project ID

        // create the userProject ref
        const userRef = doc(db, `users/${userId}`);
        await addDoc(collection(db, 'userProjects'), {project:projectRef, user: userRef, role: userRole});

        // create the companyProject ref
        if (companyId) {
            const companyRef = doc(db, `companies/${companyId}`);
            await addDoc(collection(db, 'companyProjects'), {project:projectRef, company: companyRef});
        }
    }

    return projectId;
};

export const saveOrUpdateJustification = async (justificationData: JustificationData, justificationId: string | null, userId: string) => {
    let justificationRef;

    if (justificationId) {
        justificationRef = doc(db, `justifications/${justificationId}`);
        // @ts-ignore
        await updateDoc(justificationRef, justificationData);
    } else {
        justificationRef = await addDoc(collection(db, 'justifications'), justificationData);
        justificationId = justificationRef.id;
        // create the userProject ref
        const userRef = doc(db, `users/${userId}`);
        await addDoc(collection(db, 'userJustifications'), {justification: justificationRef, user: userRef, role: 'admin'});
    }

    return justificationId;
};

export const createOrUpdateComparisonResults = async (projectId, comparisonResults) => {
    const docRef = doc(db, `comparisonResults/${projectId}`);
    const versionDate = new Date().toISOString(); // Current timestamp in ISO format

    // Assuming comparisonResults is already in the correct format
    const comparisonData = typeof comparisonResults === 'string' ? JSON.parse(comparisonResults) : comparisonResults;

    let versionNumber = 1;

    try {
        await runTransaction(db, async (transaction) => {
            const docSnapshot = await transaction.get(docRef);

            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const versions = Object.keys(data.versions || {}).map(Number).sort((a, b) => a - b);
                versionNumber = versions.length > 0 ? Math.max(...versions) + 1 : 1;
            }

            const newVersionData = {
                [versionNumber]: {
                    date: versionDate,
                    requirements: comparisonData,
                },
            };

            const updatedData = docSnapshot.exists() ? { ...docSnapshot.data(), versions: { ...docSnapshot.data().versions, ...newVersionData } } : { versions: newVersionData };

            transaction.set(docRef, updatedData);
        });

        console.log(`Comparison results updated for project ID: ${projectId} with version ${versionNumber}`);

        return {
            ref: `comparisonResults/${projectId}`, // Placeholder reference to Firestore doc
            // You can include more details here if needed
        };
    } catch (error) {
        console.error("Error creating or updating comparison results:", error);
        throw new Error("Failed to create or update comparison results.");
    }
};

export const fetchProjectById = async (projectId: string): Promise<ProjectData | null> => {
    const docRef = doc(db, 'projects', projectId);
    const docSnap = await getDoc(docRef);

    //todo we will eventually add the user to lookup their role

    if (!docSnap.exists()) {
        console.log('No such project!');
        return null;
    }

    return { id: docSnap.id, ...(docSnap.data() as Omit<ProjectData, 'id'>) };
};

export const fetchJustificationById = async (justificationId: string): Promise<JustificationData | null> => {
    const docRef = doc(db, 'justifications', justificationId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
        console.log('No such justification!');
        return null;
    }

    return { id: docSnap.id, ...(docSnap.data() as Omit<JustificationData, 'id'>) };
};

export const deleteJustificationById = async (justificationId: string) => {
    const justificationRef = doc(db, 'justifications', justificationId);
    const justificationData = (await getDoc(justificationRef)).data();

    if (!justificationData || !justificationData.owner) {
        console.error("Failed to delete justification, owner not found");
        return;
    }

    await deleteDoc(doc(db, `${justificationData.owner.path}/userJustifications`, justificationId));
    await deleteDoc(justificationRef);
}

export const deleteProjectById = async (projectId: string, userId: string) => {
    const projectRef = doc(db, 'projects', projectId);
    const projectData = (await getDoc(projectRef)).data();

    if (!projectData) {
        console.error("Failed to delete project [%s], could not find document", projectId);
        return;
    }

    // Delete the project document
    await deleteDoc(projectRef);

    // Delete from userProjects subcollection
    const userProjectRef = doc(db, `users/${userId}/userProjects/${projectId}`);
    await deleteDoc(userProjectRef);

    //@todo look at how the project is stored on the company record. right now the ref is stored in projects array. for users it's a ref stored in a sub collection. Which is best?
    // if (companyId) {
    //     const companyProjRef = doc(db, `companies/${companyId}/projects/${projectId}`);
    //     await deleteDoc(companyProjRef);
    // }
    //delete from company projects array
    const companyId = projectData.company?.id;
    if (companyId) {
        const companyRef = doc(db, `companies/${companyId}`);
        await updateDoc(companyRef, {
            projects: arrayRemove(projectRef)
        });
    }
};

export const fetchLatestComparisonResults = async (comparisonRef: string): Promise<LatestComparisonVersionData | null> => {
    const comparisonResultsRef = doc(db, comparisonRef);

    try {
        const docSnap = await getDoc(comparisonResultsRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            if (data.versions) {
                const versionNumbers = Object.keys(data.versions).map(Number).sort((a, b) => b - a);
                const latestVersionNumber = versionNumbers[0];

                const latestVersionData = data.versions[latestVersionNumber];
                if (latestVersionData && latestVersionData.requirements) {
                    // Return the whole version data including date and requirements
                    return latestVersionData;
                } else {
                    console.error("Latest version found, but missing expected data structure.");
                    return null;
                }
            } else {
                console.error("Comparison results found, but no versions available.");
                return null;
            }
        } else {
            console.error("No such comparison results document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching latest comparison results:", error);
        return null;
    }
};

export async function processRequirements(projectId, submittedRequirements) {
    for (let reqText of submittedRequirements) {
        const standardReqId = await findOrCreateTime(reqText);
        await linkRequirementToProject(projectId, standardReqId);
    }
}

export const fetchSoftwareTypes = async () => {
    const softwareTypesRef = collection(db, 'softwareTypes');
    const q = query(softwareTypesRef, orderBy('name'));
    const querySnapshot = await getDocs(q);
    const softwareTypes = querySnapshot.docs.map(doc => ({
        id: doc.id,
        value: doc.id, // Using Firestore document ID as value
        label: doc.data().name // Assuming 'name' is the field to sort by
    }));
    softwareTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    return softwareTypes;
};

export const getSoftwareTypeById = async (softwareTypeRef): Promise<SoftwareType | null> => {
    const docRef = doc(db, 'softwareTypes', softwareTypeRef);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
        console.log('No such software type!');
        return null;
    }

    return {
        ...docSnap.data() as SoftwareType, // Assuming the document structure matches the SoftwareType interface
        id: docSnap.id
    };
};

export const fetchSoftwareList = async (softwareTypeId: string | null = null): Promise<SoftwareItem[]> => {
    const softwaresRef = collection(db, 'softwares');
    let queryConstraint;

    // Check if a softwareTypeId is provided and adjust the query accordingly
    if (softwareTypeId) {
        // Create a reference to the softwareType
        const softwareTypeRef = doc(db, 'softwareTypes', softwareTypeId);
        // Query softwares that have a reference to the provided softwareType
        queryConstraint = query(softwaresRef, where('softwareTypeRefs', 'array-contains', softwareTypeRef));
    } else {
        // If no softwareTypeId is provided, prepare to fetch all softwares
        queryConstraint = query(softwaresRef);
    }

    // Execute the query
    const querySnapshot = await getDocs(queryConstraint);

    // Map through the documents using type assertion
    const softwares = querySnapshot.docs.map(docSnapshot => {
        const data = docSnapshot.data() as SoftwareItem; // Type assertion here
        return {
            value: docSnapshot.id,
            label: data.name,
            logo: data.logo,
            name: data.name,
            softwareName: data.name,
            companyName: data.companyName,
            companyId: data.companyId,
            createdAt: data.createdAt,
            modifiedAt: data.modifiedAt,
            softwareTypeRefs: data.softwareTypeRefs
        };
    });

    return softwares;
};

export const fetchSoftwareData = async (softwareIdsInput: string | string[]) => {
    // Split the string into an array of IDs
    const softwareIdsString = Array.isArray(softwareIdsInput) ? softwareIdsInput.join(',') : softwareIdsInput;
    const softwareIds = typeof softwareIdsString === 'string' ? softwareIdsString.split(',').map(id => id.trim()) : [];

    if (!softwareIds || softwareIds.length === 0) {
        return [];
    }

    const softwaresRef = collection(db, 'softwares');
    const CHUNK_SIZE = 10; // Firestore limit for 'in' query
    const chunks = chunk(softwareIds, CHUNK_SIZE);
    let softwares: SoftwareItem[] = [];

    for (const ids of chunks) {
        const queryConstraint = query(softwaresRef, where(documentId(), 'in', ids));
        const querySnapshot = await getDocs(queryConstraint);

        const chunkSoftwares = querySnapshot.docs.map(docSnapshot => {
            const data = docSnapshot.data();
            return {
                value: docSnapshot.id,
                label: data.name,
                logo: data.logo,
                softwareName: data.name,
                companyName: data.companyName,
                companyId: data.companyId,
                name: data.name,
                createdAt: data.createdAt,
                modifiedAt: data.modifiedAt,
                softwareTypeRefs: data.softwareTypeRefs
            };
        });

        softwares = [...softwares, ...chunkSoftwares];
    }

    return softwares;
};

//update to add relationship to softwares
// async function addSoftwareTypeRefsToSoftwares() {
//     const relationsRef = collection(db, "softwareTypeRelations");
//     const q = query(relationsRef,
//         where("isProcessed", "!=", true));
//     const relationsSnapshot = await getDocs(relationsRef);
//
//     // Total count of relation documents
//     const totalCount = relationsSnapshot.size;
//     let updateCounter = 0; // Counter for each update operation
//
//     for (const relationDoc of relationsSnapshot.docs) {
//         const relationData = relationDoc.data();
//
//         // Assuming relationData contains 'softwareId' and 'typeId'
//         const softwareRef = doc(db, "softwares", relationData.softwareId);
//         const softwareTypeRef = doc(db, "softwareTypes", relationData.typeId);
//
//         // Update the software document with the type reference
//         await updateDoc(softwareRef, {
//             softwareTypeRefs: arrayUnion(softwareTypeRef)
//         }).then(() => {
//             updateCounter++; // Increment the counter after successful update
//             // Flag the softwareTypeRelations document as processed
//             updateDoc(relationDoc.ref, {
//                 isProcessed: true
//             });
//             console.log(`Updated ${updateCounter}/${totalCount}: Added softwareTypeRef to softwareId ${relationData.softwareId}`);
//         }).catch(error => {
//             console.error(`Error updating softwareId ${relationData.softwareId}: `, error);
//         });
//     }
//
//     console.log('Software collection updated with type references.');
// }

// addSoftwareTypeRefsToSoftwares().catch(console.error);

async function findOrCreateTime(requirementText) {
    const requirementsRef = collection(db, 'requirements');
    const q = query(requirementsRef, where('description', '==', requirementText));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        // Requirement exists, return the first matched ID
        return querySnapshot.docs[0].id;
    } else {
        // Requirement does not exist, create a new one
        return await createStandardRequirement(requirementText);
    }
}

async function createStandardRequirement(text) {
    const requirementsRef = collection(db, 'requirements');
    const docRef = await addDoc(requirementsRef, {
        description: text,
        categories: [], // Optional: Assign categories if applicable
        usedInProjects: [] // Initialize without any projects
    });
    return docRef.id;
}

async function linkRequirementToProject(projectId, standardReqId) {
    const projectRef = doc(db, `projects/${projectId}`);
    const requirementRef = doc(db, `requirements/${standardReqId}`);

    // Link requirement to project
    await updateDoc(projectRef, {
        requirements: arrayUnion(requirementRef)
    });

    // Optionally, update the requirement document to include this project in its 'usedInProjects' array
    await updateDoc(requirementRef, {
        usedInProjects: arrayUnion(projectRef)
    });
}

//@todo clean up to work with interface
async function addSoftwareTypes(softwareTypesList) {
    const softwareTypeCollectionRef = collection(db, 'softwareTypes');
    for (const type of softwareTypesList) {
        let id = type.value.toLowerCase();

        try {
            await addDoc(softwareTypeCollectionRef, { id: id, name: type.label });
            console.log(`Added ${type.label}`);
        } catch (error) {
            console.error("Error adding software type: ", error);
        }
    }
}

// // Function to import software entries from a JSON array
export async function importFromCategoryImportList(setProgress) {
    const pageSize = 500; // Adjust based on your testing and Firestore's performance
    let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null; // Adjusted type here
    let continueProcessing = true;
    let totalImported = 0; // Keeps track of the total number of imported items

    while (continueProcessing) {
        let batchQuery = query(
            collection(db, 'CategoryImportList'),
            where('imported', '!=', true),
            limit(pageSize)
        );

        if (lastVisible) {
            batchQuery = query(batchQuery, startAfter(lastVisible));
        }

        const batchSnapshot = await getDocs(batchQuery);
        console.log(batchSnapshot.docs.length);

        if (!batchSnapshot.empty) {
            totalImported += batchSnapshot.docs.length;
            for (const docSnap of batchSnapshot.docs) {
                const item = docSnap.data();
                // Validate and process each document here...
                try {
                    await addOrUpdateSoftware({
                        label: item['ProductName'],
                        type: item['Category'],
                        businessName: item['BusinessName'],
                        website: item['Website'] || ''
                    });
                    await updateDoc(doc(db, 'CategoryImportList', docSnap.id), { imported: true });
                    console.log(`Successfully imported: ${item['ProductName']}`);
                } catch (error) {
                    console.error(`Error importing ${item['ProductName']}:`, error);
                }
                setProgress(`${totalImported} items processed.`);
            }
            lastVisible = batchSnapshot.docs[batchSnapshot.docs.length - 1];
        } else {
            continueProcessing = false; // No more documents to process
        }
    }
    console.log('Import process completed.');
}
// //
// // importFromJSON(softwareList).then(() => {
// //     console.log('Import process completed.');
// //     // Here you can do something with the modified softwareList, like logging or saving the state
// // });
//
async function addOrUpdateSoftware({ label, type, businessName, website }) {
    const softwareCollectionRef = collection(db, 'softwares');
    const softwareTypeCollectionRef = collection(db, 'softwareTypes');
    const companyCollectionRef = collection(db, 'companies');

    console.log('Adding or updating software', label, type, businessName, website);

    // Step 1: Ensure the softwareType exists
    const typeQuery = query(softwareTypeCollectionRef, where("name", "==", type));
    const typeSnapshot = await getDocs(typeQuery);

    if (typeSnapshot.empty) {
        console.error(`No software type found for name: ${type}`);
        return; // Exit if the specified softwareType doesn't exist
    }
    const softwareTypeRef = doc(db, 'softwareTypes', typeSnapshot.docs[0].id);

    // Step 2: Ensure the company exists or create a new one
    const companyQuery = query(companyCollectionRef, where("name_lower", "==", businessName.toLowerCase()));
    const companySnapshot = await getDocs(companyQuery);

    let companyDocRef;
    if (companySnapshot.empty) {
        const newCompanyDoc = await addDoc(companyCollectionRef, {
            name: businessName,
            name_lower: businessName.toLowerCase(),
            website: website,
            createdAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString()
        });
        companyDocRef = newCompanyDoc;
    } else {
        companyDocRef = companySnapshot.docs[0];
    }

    // Step 3: Check if software with the same label already exists for the company
    const softwareQuery = query(softwareCollectionRef, where("name", "==", label), where("companyId", "==", companyDocRef.id));
    const existingSoftwareSnapshot = await getDocs(softwareQuery);

    if (existingSoftwareSnapshot.empty) {
        // Software does not exist, add new software with the company and type
        const newSoftwareDocRef = await addDoc(softwareCollectionRef, {
            name: label,
            companyId: companyDocRef.id,
            companyName: businessName,
            softwareTypeRefs: [softwareTypeRef],
            createdAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString()
            // Uncomment or adjust if other fields need to be included
            // logo: software.logo,
        });
        console.log(`Added new software ${label} with ID ${newSoftwareDocRef.id}`);
    } else {
        // Software already exists, optionally update it with the new type
        const existingSoftwareDocRef = existingSoftwareSnapshot.docs[0].ref;
        await updateDoc(existingSoftwareDocRef, {
            softwareTypeRefs: arrayUnion(softwareTypeRef)
        });
        console.log(`Updated software ${label} with new type ${type}.`);
    }
}

// const softwareOptions = [
//   { value: 'serviceNow', label: 'ServiceNow', logo: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/servicenow_logo_icon_168835.png' },
//   { value: 'smartsheet', label: 'Smartsheet', logo: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/smartsheet_logo_icon_167982.png' },
//   { value: 'salesforce', label: 'Salesforce', logo: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/salesforce_logo_icon_168852.png' },
// ];

//Admin Users
export async function fetchUsers(): Promise<AppUser[]> {
    const usersQuery = query(collection(db, "Users"));
    const querySnapshot = await getDocs(usersQuery);
    const usersList: AppUser[] = [];
    querySnapshot.forEach((doc) => {
        // Ensure the object matches the AppUser interface
        const user = { id: doc.id, ...doc.data() } as AppUser;
        usersList.push(user);
    });
    return usersList;
}

export const deleteSelectedUsers = async (userIds: string[]) => {
    const batch = writeBatch(db);

    for (const userId of userIds) {
        const userRef = doc(db, `Users/${userId}`);
        batch.delete(userRef);

        // Delete user's projects if you store them under the user
        const userProjectsRef = collection(db, `Users/${userId}/userProjects`);
        const userProjectsSnapshot = await getDocs(userProjectsRef);
        userProjectsSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        // Additional logic to handle projects in the main `projects` collection
        // This part depends on how you associate users with projects
        // For example, if you have a `userId` field in projects:
        const projectsRef = collection(db, 'projects');
        const projectsQuery = query(projectsRef, where('userId', '==', userId));
        const projectsSnapshot = await getDocs(projectsQuery);
        projectsSnapshot.forEach((projectDoc) => {
            // Check if project should be deleted or reassigned before calling batch.delete(projectDoc.ref);
        });
    }

    await batch.commit();
};

// Function to search users by name
export const searchUsersByName = async (searchTerm: string): Promise<AppUser[]> => {
    if (!searchTerm.trim()) return []; // Return empty array if search term is empty or only whitespace

    const usersRef = collection(db, "Users");
    const q = query(usersRef, where("name", ">=", searchTerm), where("name", "<=", searchTerm + '\uf8ff'));
    const querySnapshot = await getDocs(q);
    const users: AppUser[] = [];
    querySnapshot.forEach((doc) => {
        users.push(doc.data() as AppUser);
    });
    return users;
};

export const createUserWithAuthAndProfile = async (email: string, password: string, userInfo: Omit<AppUser, 'id'>): Promise<AppUser | undefined> => {
    try {
        const userCredential = await AuthService.createUserWithEmailAndPassword(email, password);
        const user = userCredential.user;
        await AuthService.updateUserData(user.uid, userInfo); // Assuming this function updates Firestore data
        return { id: user.uid, ...userInfo };
    } catch (error) {
        console.error("Error creating user:", error);
        return undefined;
    }
};

export const updateUserProfile = async (userId: string, userInfo: Partial<AppUser>): Promise<void> => {
    const userRef = doc(db, `users`, userId);
    //@todo add modified date
    try {
        await updateDoc(userRef, userInfo);
        console.log("User profile updated successfully");
    } catch (error) {
        console.error("Error updating user profile:", error);
    }
};

//Get Collection Size
async function getCollectionSize(collectionPath) {
    const collectionRef = collection(db, collectionPath);
    const snapshot = await getDocs(collectionRef);
    return snapshot.size; // The number of documents in the collection
}

export const countRemainingImports = async () => {
    const countQuery = query(
        collection(db, 'CategoryImportList'),
        where('imported', '!=', true)
    );

    const querySnapshot = await getDocs(countQuery);
    return querySnapshot.size; // Returns the count of documents that match the query
};

export const fetchSoftwareByPlatformName = async (platform: string): Promise<SoftwareItem | null> => {
    const q = query(collection(db, 'softwares'), where('name', '==', platform));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        return {
            softwareName: doc.data().name,
            value: doc.id,
            label: doc.data().name,
            logo: doc.data().logo ?? null,
            name: doc.data().name,
            companyName: doc.data().companyName,
            companyId: doc.data().companyId,
            createdAt: doc.data().createdAt,
            modifiedAt: doc.data().modifiedAt,
            softwareTypeRefs: doc.data().softwareTypeRefs,
        };
    } else {
        return null;
    }
};



// async function getCollectionSize(collectionPath, filter) {
//     const collectionRef = collection(db, collectionPath);
//     const snapshot = await getDocs(collectionRef);
//     return snapshot.size; // The number of documents in the collection
// }

// Example usage Get Size
// const collectionPath = "softwares";
// getCollectionSize(collectionPath)
//     .then(size => console.log(`The size of the collection is: ${size}`))
//     .catch(error => console.error("Error getting collection size: ", error));

// console.log("Get Collection Size: ", getCollectionSize("softwares"))

//Updated the db entries with dates
const updateDocumentsWithTimestamps = async () => {
    const collectionRef = collection(db, 'softwares');
    let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null; // Adjusted type here
    const batchSize = 400; // Customize your batch size, but keep it below 500

    while (true) {
        let q = query(collectionRef, orderBy(documentId()), limit(batchSize));
        if (lastVisible) {
            q = query(collectionRef, orderBy(documentId()), startAfter(lastVisible), limit(batchSize));
        }

        const documentSnapshots = await getDocs(q);
        const batch = writeBatch(db);
        documentSnapshots.forEach((doc) => {
            batch.update(doc.ref, {
                createdAt: serverTimestamp(),
                modifiedAt: serverTimestamp()
            });
        });

        await batch.commit();
        console.log(`Updated a batch of ${documentSnapshots.size} documents`);

        if (documentSnapshots.size < batchSize) {
            break; // Exit the loop if last batch
        }
        lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    }

    console.log('Completed updating all documents with timestamps');
};

// @todo move to server side function.
export const fetchSoftwareTypeCounts = async (): Promise<Record<string, number>> => {
    const projectsRef = collection(db, 'projects');
    const querySnapshot = await getDocs(projectsRef);

    // First, fetch all software types and create a mapping of ID to name
    const softwareTypesRef = collection(db, 'softwareTypes');
    const softwareTypesSnapshot = await getDocs(softwareTypesRef);

    const softwareTypeMap: Record<string, string> = {};
    softwareTypesSnapshot.forEach((doc) => {
        const data = doc.data();
        softwareTypeMap[doc.id] = data.name;
    });

    const softwareTypeCounts: Record<string, number> = {};

    querySnapshot.forEach((doc) => {
        const project = doc.data() as ProjectData;
        if (project.softwareType && softwareTypeMap[project.softwareType]) {
            const softwareTypeName = softwareTypeMap[project.softwareType];
            if (softwareTypeCounts[softwareTypeName]) {
                softwareTypeCounts[softwareTypeName]++;
            } else {
                softwareTypeCounts[softwareTypeName] = 1;
            }
        }
    });

    return softwareTypeCounts;
};

export const fetchUserSoftwareTypeCounts = async (userId: string): Promise<Record<string, number>> => {
    const userRef = doc(db, `users/${userId}`);
    const userProjectsRef = collection(db, `userProjects`);
    const userProjectsQuery = query(userProjectsRef, where('user', '==', userRef));
    const userProjectsSnapshot = await getDocs(userProjectsQuery);

    // First, fetch all software types and create a mapping of ID to name
    const softwareTypesRef = collection(db, 'softwareTypes');
    const softwareTypesSnapshot = await getDocs(softwareTypesRef);

    const softwareTypeMap: Record<string, string> = {};
    softwareTypesSnapshot.forEach((doc) => {
        const data = doc.data();
        softwareTypeMap[doc.id] = data.name;
    });

    const softwareTypeCounts: Record<string, number> = {};

    // Get project details for each user project
    const projectPromises = userProjectsSnapshot.docs.map(async (docSnapshot) => {
        const projectRef = docSnapshot.data().project;
        const projectSnap = await getDoc(projectRef);

        if (projectSnap.exists()) {
            const project = projectSnap.data() as ProjectData;
            if (project.softwareType && softwareTypeMap[project.softwareType]) {
                const softwareTypeName = softwareTypeMap[project.softwareType];
                if (softwareTypeCounts[softwareTypeName]) {
                    softwareTypeCounts[softwareTypeName]++;
                } else {
                    softwareTypeCounts[softwareTypeName] = 1;
                }
            }
        }
    });

    await Promise.all(projectPromises);

    return softwareTypeCounts;
};


// updateDocumentsWithTimestamps().catch(console.error);

export interface CompanyData {
    id?: string;
    name: string;
    name_lower: string;
    website: string;
    createdAt: string;
    modifiedAt: string;
    organizationType?: string;
    ownIndustrySector?: string;
}

export const fetchCompanyById = async (companyId: string): Promise<CompanyData | null> => {
    const companyRef = doc(db, 'companies', companyId);
    const companyDoc = await getDoc(companyRef);

    if (!companyDoc.exists()) {
        console.log('No such project!');
        return null;
    }

    return { id: companyDoc.id, ...(companyDoc.data() as Omit<CompanyData, 'id'>) };
}
