//UserSetup.tsx
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../../_klaire/layout/core';
import { toAbsoluteUrl } from '../../../../../_klaire/helpers/AssetHelpers';
import './userSetup.scss'; // Updated to import SCSS
import { ORGANIZATION_TYPES, INDUSTRY_SECTORS, EMPLOYEE_NUMBERS, INACTIVE_DELAY, NEXT_QUESTION_DELAY, COMPANY_ROLES, QUESTIONNAIRE_USE_CASES, PERSONAL_DESCRIPTIONS, PERSONAL_PURCHASING_PREFERANCES, PERSONAL_RESEARCH_METHOD, KLAIRE_REFERALL_METHOD } from './UserSetupConstants';
import AuthService from '../../../../../services/AuthService';
import { isCompanyClaimed } from '../../../../../services/firestoreService'
import { updateCompanyData, addUserToCompany } from '../../../../../services/firestoreService';
import { ProgressBar } from './ProgressBar';
import { useAuth } from '../../index';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ActionMeta, SingleValue } from 'react-select';
import { start } from 'repl';

const UserSetupQuestionnaire = () => {

  const [formData, setFormData] = useState({
    organizationType: '',
    ownCompany: {
      label: '',
      value: ''
    },
    roleAtCompany: '',
    ownIndustrySector: '',
    ownNumberOfEmployees: '',
    personalDescription: '',
    personalPurchasingPreference: '',
    personalResearchMethod: '',
    personalReferallMethod: ''
  });

  const [loading, setLoading] = useState(false);
  const [questionnaireUseCase, setQuestionnaireUseCase] = useState(QUESTIONNAIRE_USE_CASES.UNSET);
  const [currentSection, setCurrentSection] = useState(0);
  const [furthestSectionReached, setFurthestSectionReached] = useState(0);
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);

  const personalSectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const businessSectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useAuth();
  const [setupComplete, setSetupComplete] = useState(false); // New state for tracking setup completion

  useEffect(() => {
    if (setupComplete) {
      window.location.href = '/dashboard';
    }
  }, [setupComplete, navigate]);


  const handleInputChange = (field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const totalSections = (): number => {
    if (questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS) {
      return 6;
    }
    return 5;
  }

  const finishSetup = async () => {
    let userPayload;
    let userCompanyPayload;
    if (questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS) {

      //Company Details
      await updateCompanyData(formData.ownCompany.value, {
        claimed: true,
        questionnaire: {
          organizationType: formData.organizationType,
          ownIndustrySector: formData.ownIndustrySector,
          numberOfEmployees: formData.ownNumberOfEmployees
        },
      });

      userCompanyPayload = {
        company: formData.ownCompany.value,
        roleAtCompany: formData.roleAtCompany,
      };

      userPayload = {
        setup: true,
      };
      await addUserToCompany(currentUser?.id, userCompanyPayload);
    } else {
      // Personal Details
      userPayload = {
        personalDescription: formData.personalDescription,
        personalPurchasingPreference: formData.personalPurchasingPreference,
        personalResearchMethod: formData.personalResearchMethod,
        personalReferallMethod: formData.personalReferallMethod,
        setup: true
      }
    }

    await AuthService.updateUserData(currentUser?.id, userPayload);

    // Update the currentUser in context
    if (currentUser) {
      setCurrentUser({
        ...currentUser,
        ...userPayload,
      });
    }

    setSetupComplete(true);
  };

  const progressBarOnClick = (_index: number) => {
    if (questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS) {
      businessSectionRefs.current.forEach((ref, index) => {
        if (started && ref && index == _index) {
          ref.scrollIntoView({ behavior: 'smooth' });
          setCurrentSection(index + 1);
        }
      });
    }
    else {
      personalSectionRefs.current.forEach((ref, index) => {
        if (started && ref && index == _index) {
          ref.scrollIntoView({ behavior: 'smooth' });
          setCurrentSection(index + 1);
        }
      });
    }

  }

  //whenever form changes, TODO: REFACTOR SO INDEXES ARENT SO WEIRD
  useEffect(() => {
    scrollToNextSectionAfterCompletion();
  }, [formData, started, questionnaireUseCase, finished]);

  const scrollToNextSectionAfterCompletion = () => {
    if (questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS) {
      businessSectionRefs.current.forEach((ref, index) => {
        if (ref) {
          ref.scrollIntoView({ behavior: 'smooth' });
          setCurrentSection(index + 1);
          if (furthestSectionReached < index + 1) {
            setFurthestSectionReached(currentSection + 1);
          }
        }
      });
    }
    else {
      personalSectionRefs.current.forEach((ref, index) => {
        if (ref) {
          ref.scrollIntoView({ behavior: 'smooth' });
          setCurrentSection(index + 1);
          if (furthestSectionReached < index + 1) {
            setFurthestSectionReached(currentSection + 1);
          }
        }
      });
    }
  }

  // On load
  useEffect(() => {
    //Scrolling handler
    let inactivityTimer;

    const handleScroll = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        const currentScroll = window.scrollY;
        var distances;
        if (questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS) {
          distances = businessSectionRefs.current.map((ref, index) => ({
            section: `section${index + 2}`,
            distance: Math.abs(currentScroll - (ref?.offsetTop || Infinity)),
            finalIndex: index + 1
          }));
        }
        else {
          distances = personalSectionRefs.current.map((ref, index) => ({
            section: `section${index + 2}`,
            distance: Math.abs(currentScroll - (ref?.offsetTop || Infinity)),
            finalIndex: index + 1
          }));
        }
        // Adding the initial section which starts at the top of the page
        distances.unshift({ section: 'section1', distance: Math.abs(currentScroll), finalIndex: 0 });

        const closestSectionIndex = distances.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr)).finalIndex;

        if (closestSectionIndex === 0) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setCurrentSection(0);
        } else {
          var ref;
          if (questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS) {
            ref = businessSectionRefs.current[closestSectionIndex - 1];
          }
          else {
            ref = personalSectionRefs.current[closestSectionIndex - 1];
          }

          if (ref) {
            ref.scrollIntoView({ behavior: 'smooth' });
            setCurrentSection(closestSectionIndex);
          }
        }
      }, INACTIVE_DELAY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [questionnaireUseCase]);

  const loadCompanyOptions = async (inputValue) => {
    try {
      const searchResults = await AuthService.searchCompanies(inputValue);
      return searchResults.map(company => ({ value: company.id, label: company.name }));
    } catch (error) {
      console.error("Error fetching companies:", error);
      return [];
    }
  };

  const onChangeCompany = async (selectedOption: SingleValue<{ label: string; value: string; }>, actionMeta: ActionMeta<{ label: string; value: string; }>) => {
    if (selectedOption == null) {
      console.log("Selected company option was null");
      return;
    }

    if (actionMeta.action === 'select-option') {
      handleInputChange('ownCompany', selectedOption);
    } else if (actionMeta.action === 'create-option') {
      const newCompany = await handleCreateCompany(selectedOption.label);
      if (newCompany) {
        handleInputChange('ownCompany', newCompany);
      }
    }

  };

  const handleCreateCompany = async (companyName: String) => {
    setLoading(true);
    try {
      // Assuming createOrGetCompany always returns a string ID for the new or existing company
      const companyId = await AuthService.createOrGetCompany(companyName);
      setLoading(false);
      // Ensure companyId is always a string. Handle potential null or error cases accordingly.
      if (companyId) {
        return { value: companyId, label: companyName }; // Matches expected type
      } else {
        // Handle case where companyId is null or undefined. This might involve showing an error message or a different handling logic.
        console.error("Failed to create or get the company ID.");
        setLoading(false);
        return null; // Consider how you want to handle this case. Returning null here but ensure your logic accounts for this possibility.
      }
    } catch (error) {
      console.error("Error creating company:", error);
      setLoading(false);
      return null; // Ensure error handling logic accounts for this possibility.
    }
  };

  return (
    <>
      <ProgressBar currentSection={currentSection} totalSections={totalSections()} furthestSectionReached={furthestSectionReached} progressBarOnClick={progressBarOnClick} />
      <div className="d-flex flex-column bg-black user-setup-form" style={{ height: '300vh', scrollBehavior: 'smooth' }}>
        <div className="position-fixed h-100 w-100 bg-black" style={{ zIndex: -10 }}></div>
        <div className="position-fixed w-100 bg-black" style={{ height: '10vh' }}></div>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/default-dark.svg')}
          className='h-50px h-lg-50px position-fixed start-50 translate-middle-x'
          style={{ top: 20 }}
        />

        <div className="setup-section bg-black">
          <div className="section-content">
            <p className="text-white fw-bold fs-1">Welcome to KLAiRE!</p>
            <p className="text-white fw-bold">To help us make better recommendations, we’d like to learn a little more about you</p>
            <button className="btn btn-primary mt-3" onClick={() => {
              setStarted(true);
              const nextSection = questionnaireUseCase === QUESTIONNAIRE_USE_CASES.BUSINESS ? businessSectionRefs.current[0] : personalSectionRefs.current[0];
              if (nextSection) {
                nextSection.scrollIntoView({ behavior: 'smooth' });
              }
            }}>Let's get started</button>
          </div>
        </div>

        {started && <div ref={el => { businessSectionRefs.current[0] = el; personalSectionRefs.current[0] = el; }} className="setup-section bg-black">
          <div className="section-content">
            <label className="form-label text-white ">How do you plan to use KLAiRE?</label>
            <button className="btn btn-primary mt-3" onClick={() => {
              setFinished(false);
              setQuestionnaireUseCase(QUESTIONNAIRE_USE_CASES.BUSINESS)
            }}>Business & Personal</button>
            <button className="btn btn-primary mt-3" onClick={() => {
              setFinished(false);
              setQuestionnaireUseCase(QUESTIONNAIRE_USE_CASES.BUSINESS)
            }}>Business</button>
            <button className="btn btn-primary mt-3" onClick={() => { setFinished(false); setQuestionnaireUseCase(QUESTIONNAIRE_USE_CASES.PERSONAL) }}>Personal</button>
          </div>
        </div>}


        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS && ( //First business question
          <div ref={el => businessSectionRefs.current[1] = el} className="setup-section bg-black">
            <div className="section-content">
              <label className="form-label text-white ">What is the name of your organization?</label>
              <form className='setup-form'>
                <div className="mb-3 d-flex justify-content-center flex-column">
                  <AsyncCreatableSelect
                    cacheOptions
                    defaultOptions
                    id="company-select-input"
                    loadOptions={loadCompanyOptions}
                    onChange={onChangeCompany}
                    value={formData.ownCompany}
                    placeholder="Search for your company"
                    formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                  />
                </div>
              </form>
            </div>
          </div>
        )}


        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.PERSONAL && ( //First personal question
          <div ref={el => personalSectionRefs.current[1] = el} className="setup-section bg-black">
            <div className="section-content">
              <label className="form-label text-white ">What would you say best describes you?</label>
              <form className='setup-form'>
                <div className="mb-3 d-flex justify-content-center flex-column">
                  <select
                    className="form-control"
                    value={formData.personalDescription}
                    onChange={(e) => { handleInputChange('personalDescription', e.target.value) }}//setFinished(true);
                  >
                    <option value="" disabled>Select a description</option>
                    {PERSONAL_DESCRIPTIONS.map((role, index) => (
                      <option key={index} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
          </div>
        )}

        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS && formData.ownCompany.label && ( //Second business question
          <div ref={el => businessSectionRefs.current[2] = el} className="setup-section bg-black">
            <div className="section-content">
              <label className="form-label text-white "> What best describes your role at {formData.ownCompany.label}?</label>
              <form className='setup-form'>
                <div className="mb-3 d-flex justify-content-center flex-column">
                  <select
                    className="form-control"
                    value={formData.roleAtCompany}
                    onChange={(e) => handleInputChange('roleAtCompany', e.target.value)}
                  >
                    <option value="" disabled>Select your role</option>
                    {COMPANY_ROLES.map((role, index) => (
                      <option key={index} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
          </div>
        )}


        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.PERSONAL && formData.personalDescription && ( //Second personal question
          <div ref={el => personalSectionRefs.current[2] = el} className="setup-section bg-black">
            <div className="section-content">
              <label className="form-label text-white ">What is most important to you when purchasing software?</label>
              <form className='setup-form'>
                <div className="mb-3 d-flex justify-content-center flex-column">
                  <select
                    className="form-control"
                    value={formData.personalPurchasingPreference}
                    onChange={(e) => { handleInputChange('personalPurchasingPreference', e.target.value) }}//setFinished(true);
                  >
                    <option value="" disabled>Select a preferance</option>
                    {PERSONAL_PURCHASING_PREFERANCES.map((role, index) => (
                      <option key={index} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
          </div>
        )}

        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS && formData.ownCompany.label //Third business question
          && formData.roleAtCompany && ( //company
            <div ref={el => businessSectionRefs.current[3] = el} className="setup-section bg-black">
              <div className="section-content">
                <p className="text-white">How many people work in your organization?</p>
                <form>
                  <div className="mb-3">
                    <label className="form-label text-white">Number of employees</label>
                    <select
                      className="form-control"
                      value={formData.ownNumberOfEmployees}
                      onChange={(e) => handleInputChange('ownNumberOfEmployees', e.target.value)}
                    >
                      <option value="" disabled>Select size</option>
                      {EMPLOYEE_NUMBERS.map((size, index) => (
                        <option key={index} value={size.value}>
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          )}


        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.PERSONAL &&
          formData.personalDescription && formData.personalPurchasingPreference && ( //Third personal question
            <div ref={el => personalSectionRefs.current[3] = el} className="setup-section bg-black">
              <div className="section-content">
                <label className="form-label text-white ">How would you normally research software?</label>
                <form className='setup-form'>
                  <div className="mb-3 d-flex justify-content-center flex-column">
                    <select
                      className="form-control"
                      value={formData.personalResearchMethod}
                      onChange={(e) => { handleInputChange('personalResearchMethod', e.target.value) }}//setFinished(true);
                    >
                      <option value="" disabled>Select a method</option>
                      {PERSONAL_RESEARCH_METHOD.map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          )}

        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS && formData.ownCompany.label
          && formData.roleAtCompany && formData.ownNumberOfEmployees && (//Fourth business question
            <div ref={el => businessSectionRefs.current[4] = el} className="setup-section bg-black">
              <div className="section-content">
                <label className="form-label text-white ">How would you best categorize your organization type?</label>
                <form className='setup-form'>
                  <div className="mb-3 d-flex justify-content-center flex-column">

                    <select
                      className="form-control"
                      value={formData.organizationType}
                      onChange={(e) => handleInputChange('organizationType', e.target.value)}
                    >
                      <option value="" disabled>Select organization type</option>
                      {ORGANIZATION_TYPES.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          )}


        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.PERSONAL && formData.personalDescription
          && formData.personalPurchasingPreference && formData.personalResearchMethod && ( //Fourth personal question
            <div ref={el => personalSectionRefs.current[4] = el} className="setup-section bg-black">
              <div className="section-content">
                <label className="form-label text-white ">How did you hear about KLAiRE?</label>
                <form className='setup-form'>
                  <div className="mb-3 d-flex justify-content-center flex-column">
                    <select
                      className="form-control"
                      value={formData.personalReferallMethod}
                      onChange={(e) => { setFinished(true); handleInputChange('personalReferallMethod', e.target.value) }}//
                    >
                      <option value="" disabled>Select an option</option>
                      {KLAIRE_REFERALL_METHOD.map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          )}

        {started && questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS && formData.ownCompany.label
          && formData.roleAtCompany && formData.ownNumberOfEmployees && formData.organizationType && ( //Fifth business question
            <div ref={el => businessSectionRefs.current[5] = el} className="setup-section bg-black">
              <div className="section-content">
                <label className="form-label text-white ">What is your organization’s primary industry sector?</label>
                <form className='setup-form'>
                  <div className="mb-3 d-flex justify-content-center flex-column">

                    <select
                      className="form-control"
                      value={formData.ownIndustrySector}
                      onChange={(e) => { setFinished(true); handleInputChange('ownIndustrySector', e.target.value) }}
                    >
                      <option value="" disabled>Select sector</option>
                      {INDUSTRY_SECTORS.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          )}



        {started && finished && (
          <div ref={el => questionnaireUseCase == QUESTIONNAIRE_USE_CASES.BUSINESS ? businessSectionRefs.current[6] = el : personalSectionRefs.current[5] = el} className="setup-section bg-black">
            <div className="section-content">
              <p className="text-white">All set, you're ready to start building!</p>
              <button className="btn btn-primary mt-3" onClick={finishSetup}>Continue to KLaiRE</button>
            </div>
          </div>
        )}
      </div >
    </>
  );
};

const UserSetup = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.USER_SETUP' })}</PageTitle>
      <UserSetupQuestionnaire/>
    </>
  );
};

export { UserSetup };
