import './userSetup.scss';
export const ProgressBar = ({ currentSection, totalSections, progressBarOnClick, furthestSectionReached }) => {
    const isNavigatable = (index: number) => {
        return index < currentSection || index < furthestSectionReached;
    }
    return (
        <div className="progress-bar-container" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 100 }}>
            <div style={{ display: 'flex', height: '10px', backgroundColor: '#ddd' }}>
                {Array.from({ length: totalSections }, (_, index) => (
                    <div
                        key={index}
                        style={{
                            flex: 1,
                            backgroundColor: index < currentSection ? '#4CAF50' : index < furthestSectionReached ? '#a6d8a8' : '#ddd',
                            transition: 'background-color 0.3s ease',
                            height: '10px',
                            border: 'solid 1px white',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            if (isNavigatable(index)) {
                                progressBarOnClick(index)
                            }
                        }}
                        onMouseEnter={e => { if (isNavigatable(index)) { e.currentTarget.style.transform = 'scaleY(1.5)' } }}
                        onMouseLeave={e => e.currentTarget.style.transform = 'scaleY(1)'}
                    />
                ))}
            </div>
        </div>
    );
};
