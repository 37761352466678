import React, {useEffect, useRef, useState} from "react";
import {KTIcon} from "../../../_klaire/helpers";
import {getCSS, getCSSVariableValue} from "../../../_klaire/assets/ts/_utils";
import ApexCharts, {ApexOptions} from "apexcharts";
import {fetchUserProjects, fetchUserSoftwareTypeCounts} from '../../../services/firestoreService';
import {ProjectData} from "../../modules/projects/ProjectsModels";
import {useAuth} from '../../modules/auth';
import {Link} from 'react-router-dom'

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [projectCount, setProjectCount] = useState(0);
  const [projectsWithRequirementsCount, setProjectsWithRequirementsCount] = useState(0);
  const [projectsWithSoftwareProdctsCount, setProjectsWithSoftwareProdctsCount] = useState(0);
  const [projectsWithComparisonResultsCount, setProjectsWithComparisonResultsCount] = useState(0);
  const [softwareTypeCounts, setSoftwareTypeCounts] = useState<Record<string, number>>({});

  const chartRef = useRef<HTMLDivElement | null>(null);
  const chartInstance = useRef<ApexCharts | null>(null);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const options = getChartOptions(250, softwareTypeCounts);
    if (chartInstance.current) {
      chartInstance.current.updateOptions(options);
    } else {
      chartInstance.current = new ApexCharts(chartRef.current, options);
      chartInstance.current.render();
    }
  };

  useEffect(() => {
    const loadProjects = async () => {
      if (currentUser?.uid) {
        setLoading(true);
        try {
          const userProjects = await fetchUserProjects(currentUser.uid);
          setProjects(userProjects);

          // Count all projects
          setProjectCount(userProjects.length);

          // Count projects with requirements
          const requirementsCount = userProjects.reduce((total, project) => {
            return total + (project.requirements ? project.requirements.length : 0);
          }, 0);
          setProjectsWithRequirementsCount(requirementsCount);

          // Count projects with software products
          const softwareProductsCount = userProjects.reduce((total, project) => {
            return total + (project.compareProducts ? project.compareProducts.length : 0);
          }, 0);
          setProjectsWithSoftwareProdctsCount(softwareProductsCount);

          // Count projects with comparison results
          const comparisonResultsCount = userProjects.filter(project => project.comparisonResults && Object.keys(project.comparisonResults).length > 0).length;
          setProjectsWithComparisonResultsCount(comparisonResultsCount);

        } catch (error) {
          console.error(error);
        }
        setLoading(false);
      }
    };

    const loadSoftwareTypeCounts = async () => {
      if (currentUser) {
        const counts = await fetchUserSoftwareTypeCounts(currentUser.id || '');
        console.log("Software type counts fetched:", counts); // Debugging output
        setSoftwareTypeCounts(counts);
      }
    };

    loadProjects();
    loadSoftwareTypeCounts();
  }, [currentUser]);

  useEffect(() => {
    refreshChart();

    const handleResize = () => {
      refreshChart();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [softwareTypeCounts]);

  const getBadgeColorAndLink = (status, id, projectDate) => {
    let badgeColor = 'primary';
    let projectLink = `/projects/${id}`;

    if (status === 'In Progress') {
      badgeColor = 'primary';
      projectLink = `/projects/compare/${id}`;
    } else if (status === 'Completed') {
      badgeColor = 'success';
      projectLink = `/projects/${id}`;
    }

    // Should we show danger for pending projects that missed their date?
    // if (new Date(projectDate).getTime() < Date.now() && status === 'In Progress') {
    //   status = 'Past Due';
    //   badgeColor = 'danger';
    // }

    return { badgeColor, projectLink, status };
  };

  return (
      <div className=''>
        <div className='d-flex gap-10'>
          <div className='d-flex flex-column gap-5'>
            <div className='row g-0 gap-5'>

              <div className='col bg-light-primary px-6 py-8 rounded-2 flex-grow-1'>
                <img src='/media/icons/projects.svg' className='mw-50px d-block my-2'/>
                <div className='text-black fw-semibold fs-8'>Projects</div>
                <div className='text-black fw-bold fs-1'>{projectCount}</div>
              </div>

              <div className='col bg-light-primary px-6 py-8 rounded-2 flex-grow-1'>
                <img src='/media/icons/req-generated.svg' className='mw-50px d-block my-2'/>
                <div className='text-black fw-semibold fs-8'>Requirements Generated</div>
                <div className='text-black fw-bold fs-1'>{projectsWithRequirementsCount}</div>
              </div>

            </div>
            <div className='row g-0 gap-5'>
              <div className='col bg-light-info px-6 py-8 rounded-2 flex-grow-1'>
                <img src='/media/icons/software-results.svg' className='mw-50px d-block my-2'/>
                <div className='text-black fw-semibold fs-8 mt-2'>Products Discovered</div>
                <div className='text-black fw-bold fs-1'>{projectsWithSoftwareProdctsCount}</div>
              </div>

              <div className='col bg-light-info px-6 py-8 rounded-2 flex-grow-1'>
                <img src='/media/icons/compare.svg' className='mw-50px d-block my-2'/>
                <div className='text-black fw-semibold fs-8 mt-2'>Comparisons</div>
                <div className='text-black fw-bold fs-1'>{projectsWithComparisonResultsCount}</div>
              </div>
            </div>
          </div>

          <div className={`card flex-grow-1`}>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Software Type Popularity</span>
                <span className='text-muted fw-semibold fs-7'>Distribution of software types across projects</span>
              </h3>
            </div>

            <div className='card-body p-0'>
              <div ref={chartRef} id='kt_charts_widget_6_chart'></div>
            </div>
          </div>
        </div>

        <div className='card mt-20'>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Project Name</th>
                  <th className='min-w-140px'>Date</th>
                  <th className='min-w-120px'>Description</th>
                  <th className='min-w-120px'>Status</th>
                </tr>
                </thead>
                <tbody>
                {projects.map((project) => {
                  const { badgeColor, projectLink, status } = getBadgeColorAndLink(project.status, project.id, project.createDate);
                  return (
                      <tr key={project.id}>
                        <td>
                          <Link to={projectLink} className='text-dark fw-bold text-hover-primary fs-6'>
                            {project.projectName}
                          </Link>
                        </td>
                        <td>
                          <div className='text-dark fw-bold fs-6'>
                            {new Date(project.createDate).toLocaleDateString()}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold fs-6'>
                            {project.projectDescription}
                          </div>
                        </td>
                        <td>
                        <span className={`badge badge-light-${badgeColor} fw-bolder px-4 py-3`}>
                          {status}
                        </span>
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
};

export { Dashboard };

// Chart options function
function getChartOptions(height: number, softwareTypeCounts: Record<string, number>): ApexOptions {

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const baseColor = getCSSVariableValue('--bs-primary')
  const baseLightColor = getCSSVariableValue('--bs-primary-light')
  const secondaryColor = getCSSVariableValue('--bs-info')
  const softwareTypes = Object.keys(softwareTypeCounts);
  const counts = Object.values(softwareTypeCounts);


  return {
    series: [
      {
        name: 'Software Type Count',
        type: 'bar',
        data: counts,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      stacked: true,
      height: 250, //should we get the element size to work better with mobile?
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '50%',
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: softwareTypes,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        rotate: -45, // Slant labels at a 45-degree angle
        rotateAlways: true
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString();
        },
      },
    },
    colors: [baseColor, secondaryColor, baseLightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };
}
