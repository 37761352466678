// src/path/to/UserSetupAnswerOptions.tsx

export const ORGANIZATION_TYPES = [
    'Personal',
    'Large Business',
    'Small Business',
    'Medium Sized Business',
    'State or Local Government Organization',
    'Federal Government Organization',
    'Nonprofit Organization',
    'Healthcare Organization',
    'Education (K-12)',
    'Higher Education',
];

export const QUESTIONNAIRE_USE_CASES = {
    BUSINESS: 'BUSINESS',
    PERSONAL: 'PERSONAL',
    UNSET: 'UNSET'
}

export const PERSONAL_DESCRIPTIONS = [
    `Complete beginner(e.g., "I'm still learning to use email")`,
    `Novice(e.g., "I can use email and basic web browsing")`,
    `Average user(e.g., "I use office software and social media regularly")`,
    `Competent(e.g., "I can install software and change computer settings")`,
    `Advanced(e.g., "I can code or use complex software like Photoshop")`,
    `Expert(e.g., "I work in IT or have advanced certifications")`,
    `Not sure`
]

export const PERSONAL_PURCHASING_PREFERANCES = [
    "Technical Features / Capabilities",
    "Price",
    "Ease of Use",
    "Support"
]

export const KLAIRE_REFERALL_METHOD = [
    'Event / Conference',
    'Google Search',
    'Word of Mouth',
    'The Workplace'
]

export const PERSONAL_RESEARCH_METHOD = [
    'Internet search',
    'Review sites',
    'Watch demos',
    'User Groups / Community Forums'
]

export const INDUSTRY_SECTORS = [
    'Manufacturing',
    'Healthcare',
    'Technology',
    'Retail',
    'Financial Services',
    'Government',
    'Education',
    'Other (please specify)',
];

export const COMPANY_ROLES = [
    'Chief Procurement Officer(CPO)',
    'Procurement Director',
    'Procurement Manager',
    'Sourcing Specialist',
    'Contract Manager',
    'Vendor Relationship Manager',
    'Category Manager',
    'Strategic Buyer',
    'Procurement Analyst',
    'Supply Chain Manager',
    'Procurement Coordinator',
    'e-Procurement Specialist',
    'Purchasing Agent',
    'Procurement Operations Specialist',
    'Cost Analyst',
    'Chief Information Officer(CIO)',
    'IT Director',
    'IT Manager',
    'Systems Administrator',
    'Network Engineer',
    'Software Developer',
    'Database Administrator',
    'IT Security Specialist',
    'Cloud Architect',
    'DevOps Engineer',
    'IT Support Specialist',
    'Business Analyst(IT)',
    'Data Scientist',
    'UI / UX Designer',
    'QA / Test Engineer'

]

export const EMPLOYEE_NUMBERS = [
    { value: '1', label: '1, it’s just me' },
    { value: '2-50', label: '2-50' },
    { value: '50-250', label: '50-250' },
    { value: '250-1000', label: '250 – 1,000' },
    { value: '1000-5000', label: '1,000 – 5,000' },
    { value: '5000+', label: '5,000+' },
];

export const NEXT_QUESTION_DELAY = 500;
export const INACTIVE_DELAY = 500;
