import { FC, useEffect } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App } from '../App';
import { LayoutSplashScreen } from '../../_klaire/layout/core';
import { UserSetup } from '../modules/auth/components/setup/UserSetup';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    // Optionally, show a loading screen or splash screen while checking the questionnaire setup status
    return <LayoutSplashScreen />;
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            currentUser.setup ? (
              <>
                <Route index element={<Navigate to='/dashboard' />} />
                <Route path='/*' element={<PrivateRoutes />} />
              </>
            ) : (
              <>
                <Route path='/user-setup' element={<UserSetup />} />
                <Route path='*' element={<Navigate to='/user-setup' />} />
              </>
            )
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
