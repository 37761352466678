import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_klaire/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_klaire/layout/core'
import {MasterInit} from '../_klaire/layout/MasterInit'
import {AuthProvider, useAuth} from './modules/auth'
import {ThemeModeProvider} from '../_klaire/partials'

const App = () => {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthProvider>
              <Outlet />
              <MasterInit />
            </AuthProvider>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
