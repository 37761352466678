import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../../services/firebaseInit'; // Adjust the import path as necessary
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { AppUser, FirestoreUser } from './../core/_models';

interface AuthContextType {
  currentUser: AppUser | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<AppUser | null>>;
  logout: () => Promise<void>;
  loading: boolean; // Add this line
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<AppUser | null>(null);
  const [loading, setLoading] = useState(true);
  const db = getFirestore(); // Initialize Firestore

  useEffect(() => {
    setLoading(true);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Use Firestore to fetch user data
        const userDoc = doc(db, `users/${user.uid}`);
        try {
          const docSnapshot = await getDoc(userDoc);

          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();

            let companyName = ''; // Initialize company name

            // Fetch company data if companyID is present
            if (userData.company) {
              const companyDoc = doc(db, `companies/${userData.company}`);
              const companySnapshot = await getDoc(companyDoc);
              companyName = companySnapshot.exists() ? companySnapshot.data().name : '';
            }

            const customUser: AppUser = {
              ...user,
              id: user.uid,
              globalRole: userData.globalRole,
              firstname: userData.firstname,
              lastname: userData.lastname,
              company: userData.company,
              companyName: companyName,
              title: userData.title,
              allowMarketing: userData.allowMarketing,
              termsAcceptedDate: userData.termsAcceptedDate,
              setup: userData.setup,
              country: userData.country,
              language: userData.language,
              timeZone: userData.timeZone,
              currency: userData.currency,
            };
            setCurrentUser(customUser);
          } else {
            // When Registering with Social we have to create the user separately as we don't have form values.
            //@todo can this be moved somewhere else when registering with socials?
            const newUser: FirestoreUser = {
              id: user.uid,
              email: user.email,
              firstname: user.displayName ? user.displayName.split(' ')[0] : '',
              lastname: user.displayName ? user.displayName.split(' ')[1] : '',
              globalRole: 'user',
              allowMarketing: true,
              termsAcceptedDate: new Date().toISOString(),
              setup: false
            };
            await setDoc(userDoc, newUser);
            setCurrentUser({
              ...user,
              ...newUser
            } as AppUser);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const logout = async () => {
    await auth.signOut();
    setCurrentUser(null);
  };

  const value = { currentUser, logout, loading, setCurrentUser }; // Include loading here

  return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
  );
};
