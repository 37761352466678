// authService.js
//https://github.com/capawesome-team/capacitor-firebase/blob/main/packages/authentication/docs/firebase-js-sdk.md
import {
    getAuth,
    signInWithCredential,
    reauthenticateWithCredential,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider,
    signOut,
    updateProfile,
    verifyBeforeUpdateEmail,
    updatePassword,
    linkWithCredential,
    unlink,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    EmailAuthProvider
} from 'firebase/auth';
import {
    getFirestore,
    doc,
    getDoc,
    limit,
    setDoc,
    collection,
    query,
    where,
    getDocs,
    addDoc,
    orderBy,
    startAfter,
    writeBatch,
    updateDoc
} from 'firebase/firestore';
const auth = getAuth();
const db = getFirestore();

const AuthService = {
    // Correctly use Realtime Database for user data updates
    updateUserData: async (uid, userInfo) => {
        const userRef = doc(db, `users/${uid}`);
        try {
            await setDoc(userRef, {
                ...userInfo
            }, { merge: true }); // This will update the user info fields without overwriting the entire document
            console.log("User info updated successfully");
        } catch (error) {
            console.error("Error updating user info:", error);
        }
    },
    updateUserSettings: async (uid, userSettings) => {
        const userRef = doc(db, `users/${uid}`);
        //storing in the user for now. @todo can move to collection in the future
        try {
            await setDoc(userRef, {
                ...userSettings
            }, { merge: true });
            console.log("User settings updated successfully");
        } catch (error) {
            console.error("Error updating user settings:", error);
        }
    },
    checkUserExists: async (userId) => {
        const docRef = doc(db, `users/${userId}`);
        const docSnap = await getDoc(docRef);
        return docSnap.exists();
    },
    searchCompanies: async (searchTerm) => {
        const companiesRef = collection(db, 'companies');
        const q = query(companiesRef, where('name_lower', '>=', searchTerm.toLowerCase()), where('name_lower', '<=', searchTerm.toLowerCase() + '\uf8ff'), limit(100));
        const querySnapshot = await getDocs(q);
        let matches = [];
        querySnapshot.forEach((doc) => {
            matches.push({ id: doc.id, name: doc.data().name });
        });
        return matches;
    },
    createOrGetCompany: async (companyName) => {
        const companiesRef = collection(db, 'companies');
        const trimmedCompanyName = companyName.trim();
        const companyNameLower = trimmedCompanyName.toLowerCase();
        const q = query(companiesRef, where('name_lower', '==', companyNameLower));

        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                // No company found, create a new one
                const docRef = await addDoc(companiesRef, {
                    name: trimmedCompanyName,
                    name_lower: companyNameLower
                });
                return docRef.id; // Return the new company ID
            } else {
                // Return the first matched company ID
                return querySnapshot.docs[0].id;
            }
        } catch (error) {
            console.error("Error accessing Firestore:", error);
            throw new Error("Failed to create or get company.");
        }
    },
    // getUserSubscriptionStatus: async (userId) => {
    //     const snapshot = await get(ref(db, `users/${userId}`));
    //     if (snapshot.exists()) {
    //         const userData = snapshot.val();
    //         return {
    //             isSubscribed: userData.isSubscribed,
    //             subscriptionType: userData.subscriptionType,
    //             subscriptionExpiry: userData.subscriptionExpiry,
    //         };
    //     } else {
    //         return null;
    //     }
    // },
    // updateSubscriptionStatus: async (userId, isSubscribed) => {
    //     await update(ref(db, `users/${userId}`), { isSubscribed });
    // },
    signInWithEmailAndPassword: async (email, password) => {
        const user = await signInWithEmailAndPassword(auth, email, password);
        console.log(user);
        return user;
    },
    createUserWithEmailAndPassword: async (email, password) => {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        return userCredential;
    },
    sendVerificationEmail: async (email) => {
        sendEmailVerification(auth.currentUser)
            .then(() => {
                console.log('Verification email sent. Please check your inbox and verify your email.');
            })
            .catch((error) => {
                console.error(`Error sending verification email: ${error.message}`);
            });
    },
    signInWithGoogle: async () => {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        return result;
    },
    signInWithMicrosoft: async () => {
        const provider = new OAuthProvider('microsoft.com');
        const result = await signInWithPopup(auth, provider);
        return result;
    },
    // signInWithApple: async () => {
    //     const provider = new OAuthProvider('apple.com');
    //     // Assuming you have a mechanism to obtain Apple's idToken and rawNonce
    //     // const credential = provider.credential({ idToken, rawNonce });
    //     // const result = await signInWithCredential(auth, credential);
    //     // return result;
    // },
    logout: async () => {
        await signOut(auth);
    },
    getCurrentUserProfile: () => {
        return auth.currentUser;
    },
    updateProfile: async (name) => {
        if (name) await updateProfile(auth.currentUser, { displayName: name });
    },
    changeUserPassword: async (newPassword) => {
        if (!newPassword) {
            return;
        }

        await updatePassword(auth.currentUser, newPassword);
    },
    changeUserEmail: async (newEmail, password) =>  {
        if (!newEmail ) {
            return;
        }

        const credential = EmailAuthProvider.credential(auth.currentUser.email, password)
        reauthenticateWithCredential(auth.currentUser, credential).then(() => {
            verifyBeforeUpdateEmail(auth.currentUser, newEmail);
        }).catch((e) => {
            console.error(e)
        });
    },
    linkWithGoogle: async () => {
        const provider = new GoogleAuthProvider();
        const result = await auth.currentUser.linkWithPopup(provider);
        return result;
    },
    linkWithApple: async () => {
        // Similar to signInWithApple, but use linkWithPopup or linkWithRedirect
    },
    unlinkProvider: async (providerId) => {
        await unlink(auth.currentUser, providerId);
    },
};

// async function updateCompanyNames() {
//     const companiesRef = collection(db, 'companies');
//     const batchSize = 500; // Define a reasonable batch size
//     let lastDoc = null; // This will hold the last document of each batch to paginate
//
//     while (true) {
//         let queryRef = query(companiesRef, orderBy('__name__'), limit(batchSize));
//
//         if (lastDoc) {
//             queryRef = query(queryRef, startAfter(lastDoc));
//         }
//
//         const snapshot = await getDocs(queryRef);
//         if (snapshot.empty) {
//             console.log('No more companies to update.');
//             break;
//         }
//
//         const batch = writeBatch(db);
//
//         snapshot.docs.forEach(doc => {
//             let companyName = doc.data().name;
//             if (companyName.includes('\\u0026')) {
//                 companyName = companyName.replace(/\\u0026/g, '&');
//                 console.log("Updated: ",companyName);
//                 batch.update(doc.ref, { name: companyName });
//             }
//         });
//
//         await batch.commit();
//         lastDoc = snapshot.docs[snapshot.docs.length - 1];
//         console.log('Batch updated');
//     }
// }

// updateCompanyNames().catch(console.error);

export default AuthService;




