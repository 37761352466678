import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_klaire/layout/core'
import {Dashboard} from "./Dashboard";

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <Dashboard />
    </>
  )
}

export {DashboardWrapper}
