import {useIntl} from 'react-intl'

import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  // Check if the current user is a vendor or admin
    const isVendor = currentUser?.userType === 'vendor' && currentUser.approvalStatus === true;
    const isAdmin = currentUser?.globalRole === 'admin';

  return (
    <>
      {/* Common menu items */}
      <AsideMenuItem to='/dashboard' title='Dashboard' hasBullet={true} />
      <AsideMenuItem to='/projects/list' title='All Projects' hasBullet={true} />
      <AsideMenuItem to='/projects/new' title='Create a Project' hasBullet={true} />

      <AsideMenuItem to='/account/overview' title='Account Settings' hasBullet={true} />

      {/* Admin specific menu items */}
      {isAdmin && (
          <>
              <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
                  </div>
              </div>
              <AsideMenuItem to='/admin/product-management' icon='shield-tick' title='Software management'/>

              <AsideMenuItem to='/admin/user-management/users' icon='shield-tick' title='User management'/>
              <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>In Development</span>
                  </div>
              </div>
              <AsideMenuItem to='/justifications/list' title='All Justifications' hasBullet={true} />
              <AsideMenuItem to='/justifications/generate' title='Generate a Justification' hasBullet={true}/>
          </>
      )}
        {/* Vendor specific menu items */}
        { (isVendor || isAdmin) && (
            <>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Vendor Account</span>
                    </div>
                </div>
                <AsideMenuItem to='/vendor/overview' title='Account Overview' hasBullet={true} />
                <AsideMenuItem to='/vendor/company' title='Company Information' hasBullet={true} />
                <AsideMenuItem to='/vendor/products' title='Product Management' hasBullet={true} />
            </>
        )}


{/*<AsideMenuItem to='/vendor/projects' title='Product Management' hasBullet={true} />*/}
        {/*<AsideMenuItemWithSub to='/pages/profile' title='Profile' hasBullet={true}>*/}
        {/*  <AsideMenuItem to='/profile/overview' title='Overview' hasBullet={true} />*/}
        {/*  <AsideMenuItem to='/pages/profile/campaigns' title='Campaigns' hasBullet={true} />*/}
        {/*  <AsideMenuItem to='/pages/profile/documents' title='Documents' hasBullet={true} />*/}
        {/*  <AsideMenuItem*/}
        {/*    to='/pages/profile/connections'*/}
        {/*    title='Connections'*/}
        {/*    hasBullet={true}*/}
        {/*  />*/}
        {/*</AsideMenuItemWithSub>*/}

      {/*  <AsideMenuItemWithSub to='/pages/wizards' title='Wizards' hasBullet={true}>*/}
      {/*    <AsideMenuItem*/}
      {/*      to='/pages/wizards/horizontal'*/}
      {/*      title='Horizontal'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <AsideMenuItem to='/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
      {/*  </AsideMenuItemWithSub>*/}
      {/*</AsideMenuItemWithSub>*/}

      {/*<AsideMenuItemWithSub to='/error' title='Errors' icon='cross-circle'>*/}
      {/*  <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub to='/widgets' title='Widgets' icon='element-plus'>*/}
      {/*  <AsideMenuItem to='/widgets/lists' title='Lists' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/widgets/statistics' title='Statistics' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/widgets/charts' title='Charts' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/widgets/mixed' title='Mixed' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/widgets/tables' title='Tables' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/widgets/feeds' title='Feeds' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}

      {/*<AsideMenuItemWithSub to='/apps/chat' title='Chat' icon='message-text-2'>*/}
      {/*  <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}

      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href='#'*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTIcon iconName='document' className='fs-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </>
  )
}
