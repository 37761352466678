import React, {FC} from 'react'

const FeedbackButton: FC = () => (
  <a
    href="https://docs.google.com/spreadsheets/d/1Vbt8v_vnWEdu_hzGLcgmHslsD-Q-NTQoiSX4LZLDAuE/edit?gid=0#gid=0"
    target='_blank'
    className='engage-purchase-link btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 px-5 shadow-sm rounded-top-0'
  >
    Feedback
  </a>
)

export {FeedbackButton}
