import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_klaire/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_klaire/assets/ts/_utils'
import {WithChildren} from '../../_klaire/helpers'
import { RoleBasedRoute, AdminOnlyRoute } from './roleBasedRoutes';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/vendor/ProfilePage'))
  const ProjectsPage = lazy(() => import('../modules/projects/ProjectsPage'))
  const JustificationsPage = lazy(() => import('../modules/justifications/JustificationsPage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/admin/chat/ChatPage'))
  const AdminPage = lazy(() => import('../modules/admin/AdminPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='vendor/*'
          element={
              <RoleBasedRoute allowedUserTypes={['vendor']} requireApproval={true}>
                  <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              </RoleBasedRoute>
          }
        />
        <Route
          path='projects/*'
          element={
              <SuspensedView>
                  <ProjectsPage />
              </SuspensedView>
          }
        />
        <Route
            path='justifications/*'
            element={
              <SuspensedView>
                <JustificationsPage />
              </SuspensedView>
            }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/*'
          element={
              <AdminOnlyRoute>
                <SuspensedView>
                  <AdminPage />
                </SuspensedView>
              </AdminOnlyRoute>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
